import { createStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

export const authContainerSetup = (theme: Theme) =>
  createStyles({
    default: {
      maxWidth: theme.spacing(76.7),
      margin: '0 auto',
      width: '100%',
      alignItems: 'center',
      display: 'flex',
    },
  });

export const pageRoot = (_theme: Theme) =>
  createStyles({
    default: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  });

export const basePanel = (theme: Theme) =>
  createStyles({
    default: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.grey[400],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: theme.breakpoints.values.md,
      minHeight: '100vh',
      height: 'auto',
      textAlign: 'center',
      margin: 'auto',
      position: 'relative',

      '& h1': {
        fontFamily: theme.typography.fontFamily,
      },
    },
  });

export const baseButton = (theme: Theme) =>
  createStyles({
    default: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      width: '100%',
      minWidth: '130px',
      lineHeight: '100%',
      cursor: 'pointer',
      padding: `${theme.spacing(1.5)}px ${theme.spacing(1.75)}px`,
      transition: 'none',
      border: '1px solid',
      height: '54px',
      fontWeight: 500,
      letterSpacing: 0,
      borderRadius: 0,
    },
  });

export const baseInputField = (theme: Theme) =>
  createStyles({
    default: {
      fontSize: theme.typography.fontSize,
      width: '100%',
      minHeight: 56,
      fontFamily: theme.typography.fontFamily,
    },
  });

export const baseTextField = (theme: Theme) =>
  createStyles({
    default: {
      ...baseInputField(theme).default,
    },
    input: {
      // backgroundColor: theme.colorInputFieldBackground,
      color: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamily,
      paddingLeft: 0,
      paddingRight: 0,

      // Removes the automatic white background on chrome when autofill has been used
      '&:-webkit-autofill': {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitTextFillColor: `${theme.palette.primary.main} !important`,
      },

      '&:-webkit-autofill:hover': {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitTextFillColor: `${theme.palette.primary.main} !important`,
      },

      '&:-webkit-autofill:focus': {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitTextFillColor: `${theme.palette.primary.main} !important`,
      },

      '&:-webkit-autofill:active': {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitTextFillColor: `${theme.palette.primary.main} !important`,
      },
      '&::-webkit-search-cancel-button': {
        /* Remove default */
        WebkitAppearance: 'none',
      },
    },
    inputFocused: {
      backgroundColor: `transparent !important`,
    },
    inputLabelFocused: {
      color: `${theme.palette.primary.main}`,
      fontSize: '15px',
      // @ts-ignore
      fontWeight: '400 !important',
      fontFamily: 'Gotham Book',
      transform: 'translate(14px, 17px)',
    },
  });

export const headerBackButton = (theme: Theme) =>
  createStyles({
    default: {
      color: theme.palette.primary.light,
      position: 'absolute',
      left: '0',
      justifyContent: 'left',
      paddingLeft: '0',

      '& img': {
        transform: 'rotate(90deg)',
        width: '18px',
      },
    },
  });

export const baseLandingContainer = (theme: Theme) =>
  createStyles({
    default: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: `${theme.spacing(4.75)}px 0`,
      position: 'relative',
      textAlign: 'center',
    },
  });

export const tableCellColor = () =>
  createStyles({
    default: {
      color: '#383B41',
    },
  });

export const invalidInput = (theme: Theme) =>
  createStyles({
    default: {
      fontSize: 15,
      fontStyle: 'normal',
      fontFamily: 'Lato, Arial',
      fontWeight: 100,
      letterSpacing: '0.072em',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      minHeight: theme.spacing(5),
      alignContent: 'center',
      color: '#d23228',
    },
  });

export const menuButton = (theme: Theme) =>
  createStyles({
    default: {
      background: 'white',
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.grey[50],
      },
    },
  });

export const activeMenuButton = (theme: Theme) =>
  createStyles({
    default: {
      background: fade(theme.palette.primary.main, 0.2),
      cursor: 'unset',
      '&:hover': {
        background: fade(theme.palette.primary.main, 0.2),
      },
    },
  });

export const successMessage = (theme: Theme) =>
  createStyles({
    default: {
      backgroundColor: '#D2F3DB',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      minHeight: theme.spacing(5),
      alignContent: 'center',
      border: 'solid #cddcc6 1px',
      marginTop: theme.spacing(2),
      color: '#465440',
      padding: theme.spacing(1.5),
      fontSize: '13px',
    },
  });

export const submitButton = (theme: Theme) =>
  createStyles({
    default: {
      fontFamily: 'Lato, Arial',
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      textTransform: 'uppercase',
      fontSize: '18px',
      width: '100%',
      borderRadius: '0',
      border: '1px solid black',
      lineHeight: '100%',
      cursor: 'pointer',
      padding: theme.spacing(1.75),
      transition: 'none',
      height: theme.spacing(6),

      '&:hover': {
        background: theme.palette.common.white,
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    disabled: {
      fontFamily: 'Lato, Arial',
      background: theme.palette.primary.main,
      color: `${theme.palette.grey[300]} !important`,
      textTransform: 'uppercase',
      fontSize: '18px',
      width: '100%',
      borderRadius: '0',
      border: `1px solid ${theme.palette.grey[300]}`,
      lineHeight: '100%',
      cursor: 'pointer',
      padding: theme.spacing(1.75),
      transition: 'none',
      height: theme.spacing(6),
    },
    loading: {
      fontFamily: 'Lato, Arial',
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      textTransform: 'uppercase',
      fontSize: '18px',
      width: '100%',
      borderRadius: '0',
      border: `1px solid ${theme.palette.primary.main}`,
      lineHeight: '100%',
      cursor: 'default',
      padding: theme.spacing(1.75),
      transition: 'none',
      height: theme.spacing(6),

      '&:hover': {
        cursor: 'default',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  });

export const tertiaryButton = (theme: Theme) =>
  createStyles({
    default: {
      fontFamily: 'Lato, Arial',
      background: 'transparent',
      color: theme.palette.primary.light,
      fontSize: theme.typography.h2.fontSize,
      width: '100%',
      border: 'none',
      lineHeight: theme.typography.h2.lineHeight,
      textTransform: 'unset',
      cursor: 'pointer',
      transition: 'none',
      padding: theme.spacing(1.75),
      height: theme.spacing(6),
      minHeight: theme.spacing(6),
    },
  });

export const inverseButton = (theme: Theme) =>
  createStyles({
    default: {
      fontFamily: 'Lato, Arial',
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
      border: `solid ${theme.palette.primary.main} 1px`,
      textTransform: 'capitalize',
      fontSize: '16px',
      width: '100%',
      borderRadius: '4px',
      lineHeight: '100%',
      cursor: 'pointer',
      transition: 'none',
      height: theme.spacing(6),
      minHeight: theme.spacing(6),

      '&:hover': {
        background: fade(theme.palette.primary.main, 0.1),
      },
    },
  });

export const greyButton = (theme: Theme) =>
  createStyles({
    default: {
      background: theme.palette.grey[200],
      color: theme.palette.grey[400],
      border: `solid ${theme.palette.grey[400]} 1px`,
      textTransform: 'capitalize',
      padding: `${theme.spacing(1.2)}px ${theme.spacing(2.4)}px ${theme.spacing(1.2)}px ${theme.spacing(2.4)}px`,
      fontSize: '16px',
      width: theme.spacing(25),
      minHeight: theme.spacing(4),
      maxWidth: theme.spacing(25),
      borderRadius: theme.spacing(),
      height: theme.spacing(6),

      '&:hover': {
        background: theme.palette.grey[300],
      },
    },
  });

export const textButton = (theme: Theme) =>
  createStyles({
    default: {
      background: 'inherit',
      color: theme.palette.grey[400],
      textTransform: 'capitalize',
      padding: `${theme.spacing(1.2)}px ${theme.spacing(2.4)}px ${theme.spacing(1.2)}px ${theme.spacing(2.4)}px`,
      fontSize: '12px',
      width: theme.spacing(15),
      minHeight: theme.spacing(4),
      maxWidth: theme.spacing(15),

      '&:hover': {
        background: theme.palette.grey[200],
      },
    },
  });

export const textField = (theme: Theme) =>
  createStyles({
    default: {
      color: theme.palette.secondary.light,
      margin: '16px 0px',
      background: theme.palette.primary.contrastText,
      width: '100%',
      height: theme.spacing(4),
      border: `0px`,
      fontSize: theme.spacing(1.6),

      '&:hover': {
        borderColor: theme.palette.secondary.light,
      },
    },
  });

export const panelRoot = (theme: Theme) =>
  createStyles({
    default: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: `${theme.spacing(2)}px 0px`,
    },
  });

export const stepperTabRoot = (theme: Theme) =>
  createStyles({
    default: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      minHeight: theme.spacing(50),
      padding: `${theme.spacing(2)}px 0px`,
    },
  });

export const inputLabel = (theme: Theme) =>
  createStyles({
    default: {
      color: `${theme.palette.primary.light} !important`,
      fontSize: '1.4rem',
      textTransform: 'uppercase',
    },
  });

export const inputField = (theme: Theme) =>
  createStyles({
    default: {
      color: `${theme.palette.primary.light} !important`,
      fontSize: '1.2rem',
      backgroundColor: theme.palette.grey[600],
      marginTop: theme.spacing(0.25),
      padding: theme.spacing(1.25),
      borderRadius: 0,
      borderBottom: `1px solid ${theme.palette.primary.light}`,

      // Removes the automatic white background on chrome
      '&:-webkit-autofill': {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitTextFillColor: 'white !important',
      },

      '&:-webkit-autofill:hover': {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitTextFillColor: 'white !important',
      },

      '&:-webkit-autofill:focus': {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitTextFillColor: 'white !important',
      },

      '&:-webkit-autofill:active': {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitTextFillColor: 'white !important',
      },
    },
  });

export const baseSkeleton = (theme: Theme) =>
  createStyles({
    default: {
      backgroundColor: theme.palette.primary.light,
      maxWidth: '100%',
    },
  });
