import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
// //@ts-ignore
// import gothamMediumEot from '../static/fonts/Gotham-Medium.eot';
// //@ts-ignore
// import gothamMediumEotIefix from '../static/fonts/Gotham-Medium.eot?#iefix';
// //@ts-ignore
// import gothamMediumWoff2 from '../static/fonts/Gotham-Medium.woff2';
// //@ts-ignore
// import gothamMediumWoff from '../static/fonts/Gotham-Medium.woff';
// //@ts-ignore
// import gothamMediumTtf from '../static/fonts/Gotham-Medium.ttf';
// //@ts-ignore
// import gothamMediumSvg from '../static/fonts/Gotham-Medium.svg';

// const gothamMediumRegular = {
//   fontFamily: 'Gotham Medium, sans-serif',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   src: `
//     url(${gothamMediumEot}),
//     url(${gothamMediumEotIefix}) format('embedded-opentype'),
//     url(${gothamMediumWoff2}) format('woff2'),
//     url(${gothamMediumWoff}) format('woff'),
//     url(${gothamMediumTtf})  format('truetype'),
//     url(${gothamMediumSvg}) format('svg');
//   `,
// };

const palette = {
  primary: {
    light: '#2D6380',
    main: '#062734',
    dark: '#101A35',
    contrastText: '#fff',
  },
  secondary: {
    light: '#E7EBED',
    main: '#7C888D',
    dark: '#172446',
    contrastText: '#fff',
  },
  common: {
    black: '#000',
    white: '#fff',
  },
  error: {
    main: 'rgba(235, 87, 87, 1)',
    light: 'rgba(235, 87, 87, 1)',
  },
  grey: {
    50: '#F2F2F2',
    100: '#F8F7FC',
    A100: 'rgba(152, 175, 183, 0.3)',
    A200: '#F5F7F8',
    A400: '#E4E8EC',
    A700: 'rgba(45, 99, 128, 0.05)',
  },
  text: {
    primary: '#062734',
    secondary: '#636363',
  },
};

const spacing = 10;

/** Everledger Default's Theme */
const theme = createMuiTheme({
  palette,
  spacing,
  typography: {
    fontFamily: 'Lato, sans-serif',

    h1: {
      fontFamily: 'Gotham Medium, sans-serif',
      fontWeight: 900,
      fontSize: '2.75rem',
      lineHeight: 1.167,
      letterSpacing: 0,
    },
    h2: {
      fontFamily: 'Gotham Medium, sans-serif',
      fontWeight: 900,
      fontSize: '2.5rem',
      lineHeight: 1.2,
      letterSpacing: 0,
    },
    h3: {
      fontFamily: 'Gotham Medium, sans-serif',
      fontWeight: 900,
      fontSize: '2.25rem',
      lineHeight: 1.235,
      letterSpacing: 0,
    },
    h4: {
      fontFamily: 'Gotham Medium, sans-serif',
      fontWeight: 900,
      fontSize: '1.75rem',
      lineHeight: 1.125,
      letterSpacing: 0,
    },
    h5: {
      fontFamily: 'Gotham Medium, sans-serif',
      fontWeight: 900,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: 0,
    },
    h6: {
      fontFamily: 'Gotham Medium, sans-serif',
      fontWeight: 900,
      fontSize: '1.25rem',
      lineHeight: 1.6,
      letterSpacing: 0,
    },
    subtitle1: {
      fontFamily: 'Gotham Medium, sans-serif',
      fontWeight: 900,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: 0,
    },
    subtitle2: {
      fontFamily: 'Gotham Medium, sans-serif',
      fontWeight: 700,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: 0,
    },
    body1: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    body2: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: 0,
    },
    button: {
      fontFamily: 'Gotham Medium, sans-serif',
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: 0,
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: 'Gotham Medium, sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: 0,
    },
  },
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fafafa',
          fontSize: spacing * 1.8,
          margin: spacing * 0,
          fontFamily: 'Lato, sans-serif',
          lineHeight: 1.5,
          letterSpacing: spacing * 0,
          color: palette.secondary.main,
        },
        a: {
          textDecoration: 'none',
          cursor: 'pointer',
        },
        label: {
          fontWeight: `700 !important`,
          color: palette.primary.main,
          top: `0 !important`,
        },
      },
    },
    MuiFormControl: {
      root: {
        '& .MuiOutlinedInput-root': {
          '&.MuiOutlinedInput-adornedStart': {
            padding: spacing * 0,
          },
          '& fieldset': {
            borderWidth: spacing * 0.1,
            borderStyle: 'solid',
            borderColor: `rgba(45, 99, 128, 0.2)`,
            borderRadius: 2 * 5,
            backgroundColor: palette.common.white,
            zIndex: -2,
          },
          '&:hover fieldset': {
            borderWidth: spacing * 0.1,
            borderStyle: 'solid',
            borderColor: `rgba(45, 99, 128, 1)`,
            borderRadius: 2 * 5,
          },
          '&.Mui-focused fieldset': {
            borderWidth: spacing * 0.1,
            borderStyle: 'solid',
            borderColor: palette.primary.main,
            borderRadius: 2 * 5,
          },
          '&.MuiOutlinedInput-notchedOutline fieldset': {
            borderWidth: '1px !important',
            borderColor: palette.primary.main,
            borderStyle: 'solid',
            borderRadius: 2 * 5,
            color: palette.secondary.main,
          },
          '&.Mui-error fieldset': {
            borderWidth: '1px !important',
            borderColor: palette.error.main,
            borderStyle: 'solid',
            borderRadius: 2 * 5,
          },
        },
        '& .MuiOutlinedInput-adornedEnd': {
          padding: spacing * 0,
        },
        '&.MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiInputBase: {
      root: {
        color: palette.secondary.main,
      },
    },
    MuiTextField: {
      root: {
        color: 'red',
        margin: '16px 0px',
        width: '100%',
        border: spacing * 0,
      },
    },
    MuiInputLabel: {
      root: {
        '&.Mui-focused': {
          color: palette.primary.main,
        },
        '&.Mui-error': {
          color: palette.error.main,
        },
      },
    },
  },
});

export default theme;
